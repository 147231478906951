<template>
	<div class="animated fadeIn">
		<div class="table-responsive">
			<table class="table table-hover table-striped">
				<thead>
					<tr>
						<th class="border-top-0">
							{{ translate('product') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('sku') }}
						</th>
						<th
							class="border-top-0 text-center">
							{{ translate('quantity') }}
						</th>
						<th
							class="border-top-0 text-center">
							{{ translate('options') }}
						</th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="(item) in data"
						:key="item.attributes.sku">
						<td class="align-middle">
							<img
								v-if="item.attributes.image !== null"
								:src="item.attributes.image"
								class="responsive block-inline"
								style="max-width:32px">
							<div
								v-if="item.attributes.image === null"
								style="width:32px; display:inline-block;" />
							{{ translate(item.attributes.code_name) }} {{ item.attributes.is_free ? `(${translate('free')})` : '' }}
						</td>
						<td class="align-middle">
							{{ item.attributes.sku }}
						</td>
						<td class="align-middle text-center">
							<quantity-select
								:init-qty="1"
								:select-limit="10"
								:max="99"
								:min="1"
								sub-container-class="d-flex justify-content-center"
								@change="(event) => handleQuantityChange(item, event)" />
						</td>
						<td class="align-middle text-center">
							<b-button
								v-b-tooltip.hover
								:title="translate('delete_thing', { name: translate('product') })"
								variant="primary"
								@click="$emit('remove', item)">
								<i
									class="fa fa-trash"
									aria-hidden="true" />
							</b-button>
						</td>
					</tr>
				</tbody>
			</table>
			<is-loading
				:loading="loading"
				:has-data="Object.keys(products).length > 0"
				:no-data-label="translate('no_products_selected')" />
		</div>
	</div>
</template>
<script>
import {
	Grids, Products, Report, ManualOrder, Tooltip,
} from '@/translations';
import QuantitySelect from '@/components/QuantitySelect';

export default {
	name: 'ProductsTable',
	messages: [Grids, Products, Report, ManualOrder, Tooltip],
	components: { QuantitySelect },
	props: {
		data: {
			type: Array,
			default: () => [],
		},
		loading: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			products: {},
		};
	},
	watch: {
		data(newData) {
			Object.keys(this.products).forEach((productKey) => {
				const index = newData.findIndex((item) => item.attributes.sku === productKey);
				if (index === -1) {
					delete this.products[productKey];
				}
			});
			newData.forEach((product) => {
				let found = false;
				Object.keys(this.products).forEach((productKey) => {
					if (product.attributes.sku === productKey) {
						this.handleQuantityChange(product, this.products[productKey]);
						found = true;
					}
				});
				if (!found) {
					this.handleQuantityChange(product, 1);
				}
			});
			this.$emit('update:products', this.products);
		},
	},
	methods: {
		handleQuantityChange(item, qty) {
			this.$set(this.products, item.attributes.sku, qty);
			this.$emit('update:products', this.products);
		},
	},
};
</script>
